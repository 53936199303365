import React, { Component } from "react";
import EntryForm from "./entryForm";
import SampleFile from "./sampleFile";
import "./style.css";

class FileUpload extends Component {
  render() {
    return (
      <div className="uploadForm">
        <EntryForm />
      </div>
    );
  }
}

export default FileUpload;
