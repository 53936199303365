import React from "react";
import { NavLink } from "react-router-dom";

// Object.keys(user).length >= 3 ====== normal user
// Object.keys(user).length > 3 ======= admin user
// Object.keys(user).length == 0 ====== anonymus user

const NavBar = ({ user }) => {
  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-dark">
      <a className="navbar-brand" href="#">
        eUpload
      </a>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <ul className="navbar-nav mr-auto">
          {Object.keys(user).length >= 3 && (
            <li className="nav-item">
              <NavLink className="nav-link" to="/import">
                Import File
              </NavLink>
            </li>
          )}
        </ul>
        <ul className="navbar-nav ml-auto">
          {Object.keys(user).length === 0 && (
            <li className="nav-item">
              <NavLink className="nav-link" to="/login">
                <button className="btn btn-info">Log-in</button>
              </NavLink>
            </li>
          )}
          {Object.keys(user).length >= 3 && (
            <React.Fragment>
              <li className="nav-item">
                <NavLink className="nav-link" to="/profile">
                  {user.username}
                </NavLink>
              </li>
              <li className="nav-item">
                <button
                  className="btn btn-info"
                  onClick={() => {
                    localStorage.removeItem("TOKEN");
                    window.location = "/login";
                  }}
                >
                  Log-out
                </button>
              </li>
            </React.Fragment>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
