import React, { Component } from "react";
import { toast } from "react-toastify";
import http from "../Services/httpService";
import config from "../config/default.json";

class Login extends Component {
  state = {
    account: {
      email: "",
      password: ""
    }
  };

  handleSubmit = async e => {
    e.preventDefault();

    const details = {
      client: this.state.account.email.split("@")[1].split(".")[0],
      user: this.state.account.email.split("@")[0],
      password: this.state.account.password
    };

    try {
      const response = await http.post(
        `${config.backend}/loginUpload`,
        details
      );

      localStorage.setItem("TOKEN", response.data.token);
      window.location = "/import";
    } catch (ex) {
      toast.error(`${ex.response.status} ${ex.response.data}`);
      // USE TOASTIFY
    }
  };

  render() {
    const { account } = this.state;
    return (
      <div className="m-3">
        <h2>Log-in here!</h2>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="emailLogin">Email address</label>
            <input
              type="email"
              className="form-control"
              id="emailLogin"
              aria-describedby="emailHelp"
              placeholder="Enter email"
              onChange={e => {
                const account = { ...this.state.account };
                account.email = e.currentTarget.value;
                this.setState({ account });
              }}
              value={account.email}
            />
            <small id="emailHelp" className="form-text text-muted">
              This will stay private!
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputPassword1">Password</label>
            <input
              type="password"
              className="form-control"
              id="exampleInputPassword1"
              placeholder="Password"
              onChange={e => {
                const account = { ...this.state.account };
                account.password = e.currentTarget.value;
                this.setState({ account });
              }}
              value={account.password}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Log-in
          </button>
        </form>
      </div>
    );
  }
}

export default Login;
