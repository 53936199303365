import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import jwtDecode from "jwt-decode";
import NavBar from "./Components/navBar";
import FileUpload from "./Components/FileConverter";
import Login from "./Components/login";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

class App extends Component {
  state = {
    user: {}
  };

  componentDidMount() {
    try {
      const jwt = localStorage.getItem("TOKEN");
      const user = jwtDecode(jwt);
      this.setState({ user });
    } catch (ex) {}
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer />
        <NavBar user={this.state.user || {}} />
        <main className="container">
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/import" component={FileUpload} />
            <Redirect from="/" to="/login" />
          </Switch>
        </main>
      </React.Fragment>
    );
  }
}

export default App;
