import React, { Component } from "react";
import http from "../../Services/httpService";
import { toast } from "react-toastify";
const fileDownload = require("js-file-download");

class SampleFile extends Component {
  state = {
    loading: false,
    downloadOption: ""
  };

  handleChange = event => {
    this.setState({ downloadOption: event.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState(
      {
        loading: true
      },
      async () => {
        try {
          const response = await http.post(
            "http://192.168.0.91:10000/fileDownload",
            { downloadOption: this.state.downloadOption },
            { responseType: "blob" }
          );
          fileDownload(
            response.data,
            `${this.state.downloadOption.trim()}.xlsx`
          );
          this.setState({ loading: false });
        } catch (ex) {
          toast.error("Functionabilitatea nu a fost implementata!");
        }
      }
    );
  };

  render() {
    const { loading, downloadOption } = this.state;

    return (
      <React.Fragment>
        <div className="alert alert-primary" role="alert">
          <h4 className="alert-heading" style={{ textAlign: "center" }}>
            ?
          </h4>
          Before you begin to import a file, you should download this example
          file to make sure your file has the same format as the one provided
          here.
        </div>
        <div className="alert alert-danger" role="alert">
          <h4 className="alert-heading" style={{ textAlign: "center" }}>
            !!!
          </h4>
          You must provide the same format in your file, otherwise the import{" "}
          <b>will NOT work!</b>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="selectOption">Select an option!</label>
            <select
              className="form-control"
              id="selectOption"
              onChange={this.handleChange}
            >
              <option />
              <option>Facturi intrare</option>
              <option>Facturi iesire</option>
              <option>Bonuri de consum</option>
            </select>
            {!downloadOption && (
              <div className="alert alert-danger">
                Option is not allowed to be empty!
              </div>
            )}
          </div>
          <button
            className="btn btn-primary m-2"
            disabled={loading && downloadOption}
          >
            {loading ? "Downloading..." : "Download"}
          </button>
        </form>
      </React.Fragment>
    );
  }
}

export default SampleFile;
