import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import jwtDecode from "jwt-decode";
import excel from "xlsx";
import http from "../../Services/httpService";
import fileDownload from "js-file-download";
import Loader from "react-loader-spinner";
import config from "../../config/default.json";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "font-awesome/css/font-awesome.min.css";

class EntryForm extends Component {
  state = {
    data: {
      optionSelection: "",
      file: [],
      articleCode: "",
      currentUser: ""
    },
    errors: {},
    labelName: "",
    redirect: false,
    loading: false
  };

  componentDidMount() {
    try {
      const jwt = localStorage.getItem("TOKEN");
      const decoded = jwtDecode(jwt);

      const data = { ...this.state.data };
      data.currentUser = decoded.username;

      this.setState({ data });
    } catch (ex) {}
  }

  schema = {
    optionSelection: Joi.string()
      .required()
      .label("Option"),
    articleCode: Joi.string()
      .required()
      .label("articleCode"),
    file: Joi.array()
      .min(1)
      .required()
      .label("This field"),
    currentUser: Joi.string()
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/login"></Redirect>;
    }
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  handleArticleCodeChange = e => {
    const data = { ...this.state.data };
    data.articleCode = e.target.value;

    this.setState({ data });
  };

  handleTypeChange = e => {
    const data = { ...this.state.data };
    data.optionSelection = e.target.value;

    this.setState({ data });
  };

  handleFileChange = e => {
    this.setState({ labelName: e.target.files[0].name } || "");
    const reader = new FileReader();

    const rowsArray = [];
    const data = { ...this.state.data };

    reader.onload = e => {
      const file = e.target.result;
      const wb = excel.read(file, { type: "binary" });

      wb.SheetNames.forEach(sheetName => {
        const XL_row_object = excel.utils.sheet_to_json(wb.Sheets[sheetName]);
        rowsArray.push(XL_row_object);
      });
      data.file = rowsArray;
      this.setState({ data });
    };

    reader.onerror = ex => console.log(ex);

    reader.readAsBinaryString(e.target.files[0]);
  };

  handleSubmit = async e => {
    e.preventDefault();

    if (!this.state.data.currentUser) {
      return this.setState({ redirect: true });
    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });

    if (errors) return;

    try {
      this.setState({ loading: true });
      const response = await http.post(
        `${config.backend}/invoices`,
        this.state.data,
        {
          headers: {
            "x-access-token": localStorage.getItem("TOKEN")
          },
          responseType: "blob"
        }
      );

      if (response.data.size === 7) {
        toast.success("Import finished!");
      } else {
        toast.error("Error");
        fileDownload(response.data, "errors.txt");
      }

      this.setState({ loading: false });
    } catch (ex) {
      this.setState({ loading: false });
      toast.error(`${ex.response.data}`);
    }
  };

  render() {
    const { data, errors } = this.state;

    return (
      <React.Fragment>
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={100}
          width={100}
          visible={this.state.loading}
        />
        {!this.state.loading && (
          <React.Fragment>
            <span>The import only works for XLSX extension files!</span>
            <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                <label htmlFor="typeSelection">Select a type</label>
                <select
                  className="form-control"
                  id="typeSelection"
                  value={data.optionSelection}
                  onChange={this.handleTypeChange}
                >
                  <option />
                  <option>Facturi intrare</option>
                </select>
                {errors.optionSelection && (
                  <div className="alert alert-danger">
                    {errors.optionSelection}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="articleCodeSelection">
                  Select Article Code
                </label>
                <select
                  className="form-control"
                  id="articleCodeSelection"
                  value={data.articleCode}
                  onChange={this.handleArticleCodeChange}
                >
                  <option />
                  <option>CodIntern</option>
                  <option>CodExtern</option>
                </select>
                {errors.articleCode && (
                  <div className="alert alert-danger">{errors.articleCode}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="chooseFile">
                  {this.state.labelName ? (
                    this.state.labelName
                  ) : (
                    <i className="fa fa-upload"> Choose a file ...</i>
                  )}
                </label>
                <input
                  style={{ height: "100px" }}
                  type="file"
                  ref="file"
                  id="chooseFile"
                  className="form-control"
                  onChange={this.handleFileChange}
                />
                {errors.file && (
                  <div className="alert alert-danger">{errors.file}</div>
                )}
              </div>
              <button className="btn btn-info" type="submit">
                Import
              </button>
              {this.renderRedirect()}
            </form>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default EntryForm;
