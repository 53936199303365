import axios from "axios";

axios.interceptors.response.use(null, error => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    console.log("Logging the error", error);
    alert("An unexpected error occured!");
  }

  return Promise.reject(error);
});

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem("TOKEN");
    if (token) config.headers["x-auth-token"] = token;

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default {
  get: axios.get,
  post: axios.post
};
